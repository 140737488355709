import React, { useState, useEffect } from 'react';
import { formatDate } from '../utils/dateUtils';
import { QRCodeSVG } from 'qrcode.react';
import './HistoryList.css';
import { Modal, message as antMessage, Tooltip } from 'antd';


const getApiPrefix = () => {
  const isDevelopment = process.env.NODE_ENV === 'development';
  return isDevelopment ? 'http://localhost:3005/api' : 'https://api.dogchat.today/api';
};

const HistoryList = ({ history, onDelete }) => {
  const [copiedId, setCopiedId] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [activeQR, setActiveQR] = useState(null);

  const copyToClipboard = async (userId,folderId) => {
    try {
      const fullUrl = `${getApiPrefix()}/download/${userId}/${folderId}`;
      await navigator.clipboard.writeText(fullUrl);
      setCopiedId(folderId);
      setTimeout(() => setCopiedId(null), 2000);
    } catch (error) {
      console.error('Failed to copy:', error);
    }
  };

  const handleDelete = async (folderId) => {
    Modal.confirm({
      title: '确认删除',
      content: '确定要删除这条记录吗？删除后不可恢复。',
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        try {
          
          // 删除成功后重新获取列表
          onDelete(folderId);
          antMessage.success('删除成功');
        } catch (error) {
          console.error('Delete error:', error);
          antMessage.error('删除失败: ' + error.message);
        }
      }
    });
  };

  // 计算文件统计信息
  const getFileStats = (files = []) => {
    const stats = {
      images: 0,
      videos: 0,
      texts: 0
    };

    files.forEach(file => {
      if (file.type.startsWith('image/')) {
        stats.images++;
      } else if (file.type.startsWith('video/')) {
        stats.videos++;
      } else if (file.type === 'text/plain') {
        stats.texts++;
      }
    });

    return stats;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (activeQR && !event.target.closest('.qr-wrapper')) {
        setActiveQR(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [activeQR]);

  if (!history || history.length === 0) {
    return (
      <div className="empty-history">
        <i className="fas fa-inbox"></i>
        <p>暂无上传记录</p>
      </div>
    );
  }

  return (
    <div className="history-list">
      {history.map(item => {
        const fileStats = getFileStats(item.files);
        
        return (
          <div key={item.folder_id} className="history-card">
            <div className="card-header">
              <div className="folder-name" title={item.folder_name || '未命名文件夹'}>
                {item.folder_name || '未命名文件夹'}
              </div>
              <div className="card-subheader">
                <span>{formatDate(item.created_at)}</span>
                <div className="file-stats">
                  {fileStats.images > 0 && (
                    <span className="stat-item">
                      <i className="fas fa-image"></i>
                      {fileStats.images}
                    </span>
                  )}
                  {fileStats.videos > 0 && (
                    <span className="stat-item">
                      <i className="fas fa-video"></i>
                      {fileStats.videos}
                    </span>
                  )}
                  {fileStats.texts > 0 && (
                    <span className="stat-item">
                      <i className="fas fa-file-alt"></i>
                      {fileStats.texts}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="qr-container">
              <QRCodeSVG
                value={`${getApiPrefix()}${item.downloadUrl}`}
                size={120}
                level="M"
                className="qr-code"
              />
            </div>

            <div className="card-actions">
              <Tooltip title="复制链接">
                <button
                  className={`action-btn copy-btn ${copiedId === item.folder_id ? 'copied' : ''}`}
                  onClick={() => copyToClipboard(item.user_id,item.folder_id)}
                >
                  <i className={`fas ${copiedId === item.folder_id ? 'fa-check' : 'fa-copy'}`}></i>
                </button>
              </Tooltip>
              
              <Tooltip title="查看">
                <a 
                  href={`${getApiPrefix()}${item.downloadUrl}`}
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="action-btn view-btn"
                >
                  <i className="fas fa-external-link-alt"></i>
                </a>
              </Tooltip>
              
              <Tooltip title="删除">
                <button 
                  className="action-btn delete-btn"
                  onClick={() => handleDelete(item.folder_id)}
                  disabled={deleting}
                >
                  <i className="fas fa-trash-alt"></i>
                </button>
              </Tooltip>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default HistoryList; 