import React, { useState, useEffect, useRef } from 'react';
import HistoryList from './HistoryList';
import UploadQueue from './UploadQueue';
import ExportDialog from './ExportDialog';
import './UploadArea.css';
import config from '../config';
import api from '../utils/api';
import { message, Modal } from 'antd';


const UploadArea = () => {
  const [downloadUrl, setDownloadUrl] = useState('');
  const [uploading, setUploading] = useState(false);
  const [isExisting, setIsExisting] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showExportDialog, setShowExportDialog] = useState(false);
  const [uploadQueue, setUploadQueue] = useState([]);
  const [currentUpload, setCurrentUpload] = useState(null);
  const [uploadProgress, setUploadProgress] = useState({});
  const [exportLoading, setExportLoading] = useState(false);
  const [clearLoading, setClearLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);

  // 添加 ref 来跟踪队列
  const uploadQueueRef = useRef([]);

  // 加载历史记录
  const loadHistory = async (retries = 0) => {
    try {
      const response = await api.get('/history');
      setHistory(response.data);
    } catch (error) {
      console.error('Failed to load history:', error);
      if (retries < config.MAX_RETRIES) {
        setTimeout(() => {
          loadHistory(retries + 1);
        }, config.RETRY_DELAY);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadHistory();
  }, []);

  // 处理上传成功
  const handleUploadSuccess = async (data) => {
    setDownloadUrl(data.downloadUrl);
    setIsExisting(data.isExisting);
    setUploading(false);
  };

  // 处理拖放事件
  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    setIsDragging(false);
    await addToUploadQueue(e.dataTransfer.items);
  };

  // 添加 getApiPrefix 函数
  const getApiPrefix = () => {
    const isDevelopment = process.env.NODE_ENV === 'development';
    return isDevelopment ? 'http://localhost:3005/api' : 'https://api.dogchat.today/api';
  };

  // 修改处理导出的函数
  const handleExport = async (includeFolderName) => {
    if (exportLoading) return;
    setExportLoading(true);
    try {
      const content = history
        .map(item => {
          const downloadUrl = `${getApiPrefix()}/download/${item.user_id}/${item.folder_id}`;
          if (includeFolderName) {
            return `${item.folderName || '未命名文件夹'}\t${downloadUrl}`;
          }
          return downloadUrl;
        })
        .join('\n');

      const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `图片链接_${new Date().toLocaleDateString()}.txt`;
      
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      
      setShowExportDialog(false);
    } catch (error) {
      console.error('Export failed:', error);
      message.error('导出失败，请稍后重试');
    } finally {
      setExportLoading(false);
    }
  };

  // 处理删除
  const handleDelete = async (folder_id) => {
    if (deleteLoading[folder_id]) return;
    setDeleteLoading(prev => ({ ...prev, [folder_id]: true }));
    try {
      await api.delete(`/history/uploads/${folder_id}`);
      setHistory(history.filter(item => item.folder_id !== folder_id));
    } catch (error) {
      console.error('Delete failed:', error);
      message.error('删除失败，请稍后重试');
    } finally {
      setDeleteLoading(prev => ({ ...prev, [folder_id]: false }));
    }
  };

  // 处理文件夹内容
  const processDirectory = async (entry) => {
    const files = [];
    
    const readEntries = async (dirReader) => {
      return new Promise((resolve, reject) => {
        dirReader.readEntries(async (entries) => {
          for (let entry of entries) {
            if (entry.isFile) {
              const file = await getFile(entry);
              if (isAllowedFile(file)) {
                files.push(file);
              }
            } else if (entry.isDirectory) {
              await readEntries(entry.createReader());
            }
          }
          resolve();
        }, reject);
      });
    };

    const getFile = (fileEntry) => {
      return new Promise((resolve, reject) => {
        fileEntry.file(resolve, reject);
      });
    };

    const isAllowedFile = (file) => {
      const allowedTypes = {
        'image/jpeg': true,
        'image/png': true,
        'image/gif': true,
        'video/mp4': true,
        'video/x-msvideo': true,
        'text/plain': true,
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': true,
      };
      return allowedTypes[file.type];
    };

    await readEntries(entry.createReader());
    return files;
  };

  // 添加到上传队列
  const addToUploadQueue = async (items) => {
    const newUploads = [];
    
    for (let item of items) {
      if (item.kind === 'file') {
        const entry = item.webkitGetAsEntry();
        if (entry && entry.isDirectory) {
          newUploads.push({
            id: Date.now() + Math.random(),
            name: entry.name,
            entry: entry,
            status: 'pending',
            progress: 0
          });
        }
      }
    }

    if (newUploads.length > 0) {
      setUploadQueue(prevQueue => {
        const updatedQueue = [...prevQueue, ...newUploads];
        uploadQueueRef.current = updatedQueue; // 更新 ref
        return updatedQueue;
      });
      
      // 如果没有正在上传，开始处理
      if (!uploading) {
        processUploads();
      }
    }
  };

  // 处理上传队列
  const processUploads = async () => {
    if (uploading) return;

    // 使用 ref 获取最新的队列状态
    const nextUpload = uploadQueueRef.current.find(item => item.status === 'pending');
    
    if (!nextUpload) {
      return;
    }

    try {
      setUploading(true);
      const files = await processDirectory(nextUpload.entry);
      
      if (files.length === 0) {
        throw new Error('文件夹为空或不包含支持的文件类型');
      }

      const formData = new FormData();
      formData.append('folderName', nextUpload.name);
      files.forEach(file => formData.append('files', file));

      const response = await api.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (event) => {
          if (event.lengthComputable) {
            const progress = (event.loaded / event.total) * 100;
            setUploadProgress(prev => ({
              ...prev,
              [nextUpload.id]: progress
            }));
          }
        }
      });

      // 更新上传状态
      setUploadQueue(prev => {
        const updatedQueue = prev.map(item => 
          item.id === nextUpload.id 
            ? { ...item, status: 'completed' }
            : item
        );
        uploadQueueRef.current = updatedQueue; // 更新 ref
        return updatedQueue;
      });

      // 处理上传成功
      setDownloadUrl(response.data.downloadUrl);
      setIsExisting(response.data.isExisting);
      await loadHistory();

    } catch (error) {
      console.error('Upload failed:', error);
      setUploadQueue(prev => {
        const updatedQueue = prev.map(item => 
          item.id === nextUpload.id 
            ? { ...item, status: 'error', error: error.message }
            : item
        );
        uploadQueueRef.current = updatedQueue; // 更新 ref
        return updatedQueue;
      });
    } finally {
      setUploading(false);
      
      // 检查是否还有待处理的文件
      if (uploadQueueRef.current.some(item => item.status === 'pending')) {
        setTimeout(processUploads, 100);
      }
    }
  };

  // 添加清空历史记录的函数
  const handleClearHistory = async () => {
    if (clearLoading) return;
    
    Modal.confirm({
      title: '确认清空',
      content: '确定要清空所有历史记录吗？此操作不可恢复！',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        setClearLoading(true);
        try {
          const response = await api.delete('/history/clear?confirmation=CONFIRM_CLEAR_ALL');
          if (response.data.success) {
            setHistory([]);
            message.success('历史记录已清空');
          } else {
            throw new Error(response.data.error || '清空失败');
          }
        } catch (error) {
          console.error('Clear history failed:', error);
          message.error('清空历史记录失败：' + error.message);
        } finally {
          setClearLoading(false);
        }
      }
    });
  };

  return (
    <div className="split-layout">
      <div className="upload-section">
        {/* <h1 className="page-title">{config.APP_NAME}</h1> */}
        
        {/* <div className="qr-code">
          <img src="/qr-code.png" alt="扫码上传" />
          <div className="qr-code-text">
            扫描二维码在手机上传文件
          </div>
        </div> */}
        
        <div 
          className={`upload-area ${isDragging ? 'dragging' : ''}`}
          onDragOver={(e) => e.preventDefault()}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          {uploading ? (
            <div className="status">
              <div className="spinner"></div>
              <p>正在上传...</p>
            </div>
          ) : (
            <div className="upload-content">
              <div className="upload-icon">
                <i className="fas fa-cloud-upload-alt"></i>
              </div>
              <p className="upload-text">将文件夹拖拽到此处上传</p>
              <p className="sub-text">
                支持的文件格式：<br />
                • 图片：JPG、PNG、GIF<br />
                • 视频：MP4、AVI<br />
                • 文本：TXT<br />
                • 文档：DOCX
              </p>
            </div>
          )}
        </div>

        <UploadQueue 
          queue={uploadQueue} 
          progress={uploadProgress}
        />

        {downloadUrl && (
          <div className="result-panel">
            <div className={`status-message ${isExisting ? 'existing' : 'success'}`}>
              {isExisting ? '该文件夹已经上传过！' : '上传成功！'}
            </div>
          </div>
        )}
      </div>

      <div className="history-section">
        <div className="history-header">
          <h2>
            历史上传记录
            <span style={{ 
              fontSize: '14px', 
              color: '#8c8c8c', 
              marginLeft: '8px',
              fontWeight: 'normal' 
            }}>
              (共 {history.length} 条)
            </span>
          </h2>
          <div className="history-actions">
            {history.length > 0 && (
              <>
                <button 
                  className="export-btn"
                  onClick={() => setShowExportDialog(true)}
                  disabled={exportLoading}
                  title="导出所有链接到文本文件"
                >
                  <i className="fas fa-file-export"></i>
                  {exportLoading ? '导出中...' : '导出链接'}
                </button>
                <button 
                  className="clear-btn"
                  onClick={handleClearHistory}
                  disabled={clearLoading}
                  title="清空所有历史记录"
                >
                  <i className="fas fa-trash-alt"></i>
                  {clearLoading ? '清空中...' : '清空所有记录'}
                </button>
              </>
            )}
          </div>
        </div>
        <div className="history-content">
          <HistoryList 
            history={history}
            loading={loading}
            onDelete={handleDelete}
            deleteLoading={deleteLoading}
          />
        </div>
      </div>

      {showExportDialog && (
        <ExportDialog
          onExport={handleExport}
          onClose={() => setShowExportDialog(false)}
          loading={exportLoading}
        />
      )}
    </div>
  );
};

export default UploadArea; 